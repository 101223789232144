const PageTokenTranslations = {
  pt: {
    hero: {
      info: "A liquidez do antigo token BASE no UNISWAP será removida em 1° de fevereiro e mover-se para SOLANA.",
      header: "Sobre",
      title: "O novo Token Aizzy",
      description:
        "$AIZ é o token de criptomoeda nativo da plataforma Aizzy. Ele é usado para operações na rede, transações e, o mais importante, para usar a plataforma de forma sem permissão e anônima.",
      button: "Obtenha $AIZ",
    },
    sectionOne: {
      card: "Leia nosso whitepaper com todas as informações sobre a Aizzy",
      view: "Ver whitepaper",
      descriptionOne:
        "$AIZ é mais do que um token—é a chave para uma nova era de produtividade.",
      descriptionTwo:
        "Construído para um futuro Web3, ele capacita indivíduos a assumirem o controle de seus fluxos de trabalho digitais, desbloqueando todo o potencial criativo sem sacrificar segurança ou anonimato.",
    },
    sectionTwo: {
      header: "Visão geral da Tokenomics",
      title:
        "Projetado para promover transparência, envolvimento da comunidade e sustentabilidade a longo prazo",
      cardLiquidity: {
        supply: "Fornecimento de tokens Aizzy",
        poots: "Incinerado em pools de liquidez",
        treasure: "Carteiras de tesouro",
        founder: "Diretamente para o fundador",
      },
      cardDistribution: {
        split: "Divisão da distribuição de tokens",
        locked: "Bloqueado",
        phase: "Fase",
        unlocked: "Desbloqueado",
        about: "Cerca de TBD tokens",
        company: "A empresa possuirá apenas TBD",
        founder: "O fundador receberá TBD",
      },
      cardDex: {
        description: "Adquira no Dextools, Jupiter e mais",
      },
      cardSolanaNetwork: {
        building: "CONSTRUINDO NA",
        solana: "Rede Solana",
        lower: "Menores custos de transação",
        faster: "Maior velocidade de transação",
        enhanced: "Maior escalabilidade",
      },
    },
    building: {
      title: "Pronto para construir? Compre e venda Aizzy",
      description: "Conecte sua carteira e selecione seu método preferido",
    },
    sectionThree: {
      title: "Entre em contato",
      description:
        "Descubra o projeto através de nossos canais. Veja o que está acontecendo e como estamos transformando a indústria.",
      links: {
        discord: "Participe da discussão no Discord",
        telegram: "Pergunte-nos no Telegram",
        twitter: "Siga-nos no X",
        github: "Contribua no Github",
        tiktok: "Veja as novidades no TikTok",
      },
    },
  },
  es: {
    hero: {
      info: "La liquidez del antiguo token BASE en UNISWAP se eliminará el 1 de febrero y se moverá a SOLANA.",
      header: "Sobre",
      title: "El nuevo Token Aizzy",
      description:
        "$AIZ es el token de criptomoneda nativo de la plataforma Aizzy. Se utiliza para operaciones de red, transacciones y, lo más importante, para usar la plataforma sin permisos y con anonimato.",
      button: "Obtén $AIZ",
    },
    sectionOne: {
      card: "Lee nuestro whitepaper con toda la información sobre Aizzy",
      view: "Ver whitepaper",
      descriptionOne:
        "$AIZ es más que un token—es la clave para una nueva era de productividad.",
      descriptionTwo:
        "Diseñado para un futuro Web3, empodera a las personas para tomar el control de sus flujos de trabajo digitales, desbloqueando todo su potencial creativo sin sacrificar seguridad ni anonimato.",
    },
    sectionTwo: {
      header: "Resumen de Tokenomics",
      title:
        "Diseñado para fomentar la transparencia, la participación comunitaria y la sostenibilidad a largo plazo",
      cardLiquidity: {
        supply: "Suministro de tokens Aizzy",
        poots: "Incinerado en pools de liquidez",
        treasure: "Carteras del tesoro",
        founder: "Directamente al fundador",
      },
      cardDistribution: {
        split: "División de la distribución de tokens",
        locked: "Bloqueado",
        phase: "Fase",
        unlocked: "Desbloqueado",
        about: "Aproximadamente TBD tokens",
        company: "La empresa poseerá solo el TBD",
        founder: "El fundador recibirá el TBD",
      },
      cardDex: {
        description: "Adquiérelo en Dextools, Jupiter y más",
      },
      cardSolanaNetwork: {
        building: "CONSTRUIDO EN",
        solana: "Red Solana",
        lower: "Menores costos de transacción",
        faster: "Mayor velocidad de transacción",
        enhanced: "Mayor escalabilidad",
      },
    },
    building: {
      title: "¿Listo para empezar a construir? Compra y vende Aizzy",
      description: "Conecta tu billetera y selecciona tu método preferido",
    },
    sectionThree: {
      title: "Ponte en contacto",
      description:
        "Descubre el proyecto a través de nuestros canales. Ve lo que está sucediendo y cómo estamos transformando la industria.",
      links: {
        discord: "Únete a la discusión en Discord",
        telegram: "Pregúntanos en Telegram",
        twitter: "Síguenos en X",
        github: "Contribuye en Github",
        tiktok: "Mira las novedades en TikTok",
      },
    },
  },
  en: {
    hero: {
      info: "The liquidity of the old BASE token on UNISWAP will be removed on February 1st and moved to SOLANA.",
      header: "About",
      title: "The new Aizzy Token",
      description:
        "$AIZ is the native cryptocurrency token for Aizzy platform. It is used for network operations, transactions and for most important, to use the platform, permisionless and anonimity.",
      button: "Get $AIZ",
    },
    sectionOne: {
      card: "Read our whitepaper with the full information of Aizzy",
      view: "View whitepaper",
      descriptionOne:
        "$AIZ is more than a token—it’s the key to a new era of productivity.",
      descriptionTwo:
        "Built for a Web3 future, it empowers individuals to take control of their digital workflows and unlocking your full creative potential without sacrificing security or anonymity.",
    },
    sectionTwo: {
      header: "Tokenomics overview",
      title:
        "Designed to foster transparency, community involvement, and long-term sustainability",
      cardLiquidity: {
        supply: "Aizzy token supply",
        poots: "Incinerated on liquidity pools",
        treasure: "Treasure wallets",
        founder: "Directly to founder",
      },
      cardDistribution: {
        split: "Token distribution split",
        locked: "Locked",
        phase: "Phase",
        unlocked: "Unlocked",
        about: "About TBD tokens",
        company: "Company will own just the TBD",
        founder: "Founder will receive TBD",
      },
      cardDex: {
        description: "Get it on Dextools, Jupiter and more",
      },
      cardSolanaNetwork: {
        building: "BUILDING ON",
        solana: "Solana Network",
        lower: "Lower transaction costs",
        faster: "Faster transaction speed",
        enhanced: "Enhanced scalability",
      },
    },
    building: {
      title: "Ready to start building? Buy and sell Aizzy",
      description: "Connect your wallet and select your preferred method",
    },
    sectionThree: {
      title: "Get in touch",
      description:
        "Discover the project across our channels. See what’s happening and how we’re transforming the industry.",
      links: {
        discord: "Join the Discussion on Discord",
        telegram: "Ask us on Telegram",
        twitter: "Follow us on X",
        github: "Get involved on Github",
        tiktok: "View the lastest on Tik tok",
      },
    },
  },
};

export default PageTokenTranslations;
