import { IconArrowUpRight } from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import { Link } from "react-router-dom";

const CardGetInTouch = ({ text, link }) => {
  return (
    <Link
      to={link}
      className="d-flex justify-content-between "
      target="_blank"
      style={{
        background: COLORS.white,
        borderRadius: 8,
        padding: 16,
        width: 410,
      }}
    >
      <div className="d-flex align-items-center gap-3">
        <div
          style={{
            background: COLORS.gray900,
            height: 20,
            width: 20,
            borderRadius: "50%",
          }}
        ></div>
        <span style={{ color: COLORS.gray900, fontSize: 14 }}>{text}</span>
      </div>
      <IconArrowUpRight stroke={1.5} size={20} />
    </Link>
  );
};

export default CardGetInTouch;
