import {
  IconChevronsRight,
  IconDatabase,
  IconRobotFace,
  IconShield,
  IconSquare4,
} from "@tabler/icons-react";
import COLORS from "../../../utils/constants/colors";
import CardLandingPageType1 from "../../Cards/CardLandingPageType1";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import UseTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";
import Elipse from "../../Add-ons/Elipse";
import { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SectionTwoLandingPage = () => {
  const state = useSelector(state => state);
  const { t } = UseTranslation(LandingPageI18n);

  const breakpointLg =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  const arrayCards = [
    {
      img: <IconRobotFace stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.robot"),
      top: breakpointLg ? 344 : 90,
      right: breakpointLg ? -10 : -126,
      id: 1,
    },
    {
      img: <IconChevronsRight stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.chevron"),
      bottom: 127,
      top: 416,
      left: 880,
      right: -150,
      id: 2,
    },
    {
      img: <IconShield stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.shield"),
      bottom: breakpointLg ? 97 : 55,
      left: 158,
      top: breakpointLg ? 344 : "unset",
      right: 650,
      id: 3,
    },
    {
      img: <IconDatabase stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.datas"),
      top: breakpointLg ? 344 : -40,
      right: breakpointLg ? 321 : 338,
      id: 4,
    },
    {
      img: <IconSquare4 stroke={1.5} size={breakpointLg ? 19 : 25} />,
      text: t("sectionTwo.cards.tecnology"),
      top: breakpointLg ? 416 : 92,
      left: breakpointLg ? -190 : -110,
      right: 180,
      id: 5,
    },
  ];

  useLayoutEffect(() => {
    const scroller = document.querySelector(".main-landing-page") || window;
  
    gsap.registerPlugin(ScrollTrigger);
  
    requestAnimationFrame(() => {
      ScrollTrigger.defaults({
        scroller,
      });
  
      gsap.fromTo(
        ".section-two",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          ease: "easeInOut",
          scrollTrigger: {
            trigger: ".section-one",
            start: breakpointLg ? "top -100" : "top -400",
            end: breakpointLg ? "bottom 0" : "bottom -15%",
            scrub: true,
            scroller: ".main-landing-page",
          },
        }
      );
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);
  

  return (
    <section
      className="d-flex justify-content-center section-two"
      id="about"
    >
      <div
        style={{
          width: "100%",
          maxWidth: 1440,
          padding: breakpointLg ? "120px 16px 16px 16px" : "120px 80px",
        }}
      >
        <div
          className="h-100 d-flex justify-content-center position-relative overflow-hidden"
          style={{
            background: COLORS.gray100,
            minHeight: breakpointLg ? 512 : 580,
            borderRadius: 48,
            alignItems: breakpointLg ? "flex-start" : "center",
            width: "100%",
            padding: 16,
          }}
        >
          {!breakpointLg &&
            [600, 800, 1000, 1200].map(size => (
              <Elipse key={size} size={size} />
            ))}
          <TitleLandingPage
            styleMain={{
              marginTop: breakpointLg ? 20 : 0,
            }}
            darkMode
            mediumFont
            gap={breakpointLg ? 12 : 24}
            alignItems={"center"}
            textAlign={"center"}
            headerText={t("sectionTwo.badge")}
            headerWidth={67}
            widthTitle={500}
            border={`1px solid ${COLORS.gray200}`}
            borderRadius={100}
            title={t("sectionTwo.title")}
            description={t("sectionTwo.description")}
            showButton={true}
            buttonPadding={"8px 30px"}
          />
          {breakpointLg
            ? arrayCards.map((item, index) => (
                <CardLandingPageType1
                  key={index}
                  img={item.img}
                  text={item.text}
                  right={item.right}
                  top={item.top}
                />
              ))
            : arrayCards.map((item, index) => (
                <CardLandingPageType1
                  key={index}
                  img={item.img}
                  text={item.text}
                  bottom={item.bottom}
                  right={item.right}
                  left={item.left}
                  top={item.top}
                />
              ))}
        </div>
      </div>
    </section>
  );
};

export default SectionTwoLandingPage;
