import UseTranslation from "../../../hooks/useTranslation";
import PageTokenTranslations from "./PageTokenTranslationsi18n";

const TokenSupplyComponent = () => {
  const { t } = UseTranslation(PageTokenTranslations);
  return (
    <div className="token-supply-container">
      {/* Barra de distribuição */}
      <div className="distribution-bar">
        <div className="bar-section incinerated"></div>
        <div className="bar-section treasure"></div>
        <div className="bar-section founder"></div>
      </div>

      {/* Informações de texto */}
      <div className="token-info">
        <div>
          <h1 className="token-amount">TBD</h1>
          <p className="token-label">{t("sectionTwo.cardLiquidity.supply")}</p>
        </div>
        <ul className="legend">
          <li className="legend-item">
            <span className="legend-color incinerated"></span>
            <span>{t("sectionTwo.cardLiquidity.poots")}</span>
          </li>
          <li className="legend-item">
            <span className="legend-color treasure"></span>
            <span>{t("sectionTwo.cardLiquidity.treasure")}</span>
          </li>
          <li className="legend-item">
            <span className="legend-color founder"></span>
            <span>{t("sectionTwo.cardLiquidity.founder")}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TokenSupplyComponent;
