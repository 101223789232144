import { useState } from "react";
import { deleteAllChats } from "../api";
import { ButtonDeleteAllChatsI18n } from "../components/Buttons/ButtonDeleteAllChats.i18n";
import { UseRedux } from "../hooks/index";
import UseTranslation from "../hooks/useTranslation";
import { QUERY_KEYS, queryClient } from "../libs/ReactQuery";
import { changeSelectedChat } from "../redux/actions";
import { ChatRepository } from "../services/indexedDB/ChatRepository";
import {
  showNotificationConfirmation,
  showErrorNotification,
  showSuccessNotification,
} from "../services/notifications";
import { setConfigsMainChat } from "../redux/general/action";
import { KEYS_MAIN_CHAT } from "../services/chat/defaultConfigsMainChat";

const UseDeleteAllChats = () => {
  const { dispatch } = UseRedux();
  const { t } = UseTranslation(ButtonDeleteAllChatsI18n);
  const [isDeleting, setIsDeleting] = useState(false);
  const chatRepository = new ChatRepository();

  const handleDeleteAllChats = async () => {
    if (isDeleting) return;

    setIsDeleting(true);
    try {
      const confirmed = await showNotificationConfirmation(t("confirm.delete-all-chats"));

      if (confirmed) {
        await deleteAllChats();

        dispatch(changeSelectedChat(null));
        dispatch(setConfigsMainChat(KEYS_MAIN_CHAT.chatData, null));

        queryClient.setQueryData(QUERY_KEYS.CHANNELS, []);
        chatRepository.deleteAllChats();

        showSuccessNotification("Chats deletados com sucesso!");
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : "Ocorreu um erro inesperado";
      showErrorNotification(errorMessage);
    } finally {
      setIsDeleting(false);
    }
  };
  return { handleDeleteAllChats, isDeleting };
};
export default UseDeleteAllChats;
