import { useSelector } from "react-redux";
import COLORS from "../../../utils/constants/colors";
import FooterLandingPage from "../LandingPage/FooterLandingPage";
import NavLandingPage from "../LandingPage/NavLandingPage";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import UseTranslation from "../../../hooks/useTranslation";
import CardLandingPageSectionFive from "../../Cards/CardLandingPageSectionFive";
import CardLandingPageType2 from "../../Cards/CardLandingPageType2";
import imgAIZ from "../../../assets/images/icons-new-layout/Asset 12.png";
import tokenSmall from "../../../assets/images/icons-new-layout/token-small.png";
import tokenBig from "../../../assets/images/icons-new-layout/token-big.png";
import tokenMedium from "../../../assets/images/icons-new-layout/token-medium.png";
import { IconAlertCircle, IconX } from "@tabler/icons-react";
import TokenSupplyComponent from "./TokenSupplyComponent";
import TokenDistributionSplit from "./TokenDistributionSplit";
import dex from "../../../assets/images/icons-new-layout/dextools.png";
import jupter from "../../../assets/images/icons-new-layout/jupter.png";
import dextools from "../../../assets/images/icons-new-layout/dex.png";
import elipses from "../../../assets/images/icons-new-layout/tiker-elipses.png";
import SolanaNetwork from "./SolanaNetwork";
import CardGetInTouch from "./CardGetInTouch";
import PageTokenTranslations from "./PageTokenTranslationsi18n";
import { useState } from "react";
import TitleColorsLanding from "../../Add-ons/TitleColorsLanding";
const Token = () => {
  const [activeTab, setActiveTab] = useState("Jupiter");
  const [showAlert, setShowAlert] = useState(true);
  const state = useSelector(state => state);
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;
  const isMobileMd = state.configsGeneralReducer.windowWidth < BREAKPOINTS.MD;

  const { t } = UseTranslation(PageTokenTranslations);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const buttons = [
    {
      text: "Jupiter",
      disabled: false,
    },
    {
      text: "Dextools",
      disabled: true,
    },
    {
      text: "Phantom",
      disabled: true,
    },
  ];

  // const getIframeContent = () => {
  //   switch (activeTab) {
  //     case "Jupiter":
  //       return (
  //         <iframe
  //           src="https://jup.ag/"
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //           title="Jupiter"
  //           className="iframe-content"
  //         />
  //       );
  //     case "Dextools":
  //       return (
  //         <iframe
  //           src="https://www.dextools.io/"
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //           title="Dextools"
  //           className="iframe-content"
  //         />
  //       );
  //     case "Phantom":
  //       return (
  //         <iframe
  //           src="https://phantom.app/"
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //           title="Phantom"
  //           className="iframe-content"
  //         />
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      <NavLandingPage />
      <div
        className="d-flex flex-column justify-content-between align-items-center w-100 gap-5 overflow-auto scroll-bar-none"
        style={{ paddingTop: isMobile ? "110px" : "140px", height: "100vh" }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center w-100"
          style={{
            padding: !isMobile && "0 80px 0 80px",
            gap: isMobile ? 60 : 110,
            maxWidth: 1440,
          }}
        >
          <div className="w-100 h-100 d-flex flex-column gap-4">
            {showAlert && (
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  background: COLORS.gray100,
                  minHeight: 38,
                  borderRadius: 12,
                  padding: "7px 16px",
                  gap: 8,
                  width: isMobile ? "calc(100% - 32px)" : "100%",
                  alignSelf: "center",
                }}
              >
                <div className="d-flex gap-2 align-items-center">
                  <IconAlertCircle
                    style={{
                      minWidth: 20,
                      minHeight: 20,
                      maxWidth: 20,
                      maxHeight: 20,
                    }}
                    stroke={1.5}
                  />
                  <p>{t("hero.info")}</p>
                </div>
                <button
                  onClick={handleCloseAlert}
                  style={{
                    background: COLORS.white,
                    borderRadius: 6,
                    minWidth: 22,
                    minHeight: 22,
                    maxWidth: 22,
                    maxHeight: 22,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconX size={14} stroke={1.5} />
                </button>
              </div>
            )}
            <div
              className="w-100 d-flex align-items-start justify-content-between pb-5"
              style={{
                gap: isMobile ? 30 : 60,
                flexDirection: isMobile && "column",
                padding: isMobile && "0 16px",
              }}
            >
              <div className="h-100 d-flex flex-column justify-content-center align-items-start">
                <TitleLandingPage
                  title={
                    <TitleColorsLanding
                      title={t("hero.title")}
                      numLastWords={2}
                      style={{ fontSize: isMobile ? 40 : 48 }}
                    />
                  }
                  headerText={t("hero.header")}
                  description={t("hero.description")}
                  darkMode
                  headerWidth={70}
                  borderRadius={100}
                  border={`1px solid ${COLORS.gray200}`}
                  textAlign={"start"}
                  justifyContent={"start"}
                  alignItems={"start"}
                  gap={20}
                  styleMain={{ width: "100%", gap: 12 }}
                  styleDescription={{ color: COLORS.gray700, maxWidth: 500 }}
                />
                {/* <ButtonType1
                  text={t("hero.button")}
                  darkMode
                  props={{
                    disabled: false,
                    style: {
                      padding: "8px 25px",
                      borderRadius: "8px",
                      marginTop: 16,
                    },
                  }}
                /> */}
              </div>

              <CardLandingPageType2
                background={COLORS.gray100}
                maxWidth={628}
                minHeight={isMobile ? 300 : 400}
                className={
                  "d-flex justify-content-center justify-content-lg-start align-items-end position-relative overflow-visible"
                }
              >
                <img
                  src={imgAIZ}
                  alt=""
                  style={{
                    position: "absolute",
                    top: 63,
                    left: isMobile ? -100 : -135,
                    width: isMobile && 250,
                    height: isMobile && 250,
                  }}
                />
                <img
                  src={tokenBig}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: -10,
                    right: isMobile ? 0 : 35,
                    width: isMobile && 220,
                    height: isMobile && 150,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: isMobile ? 0 : 35,
                    width: 180,
                    height: 180,
                    zIndex: 1,
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={tokenMedium}
                    alt=""
                    width={180}
                    style={{
                      position: "absolute",
                      top: isMobile ? -60 : -80,
                      zIndex: 0,
                      width: 150,
                      height: 150,
                    }}
                  />
                </div>
                <img
                  src={tokenSmall}
                  alt=""
                  style={{
                    position: "absolute",
                    top: 83,
                    right: isMobile ? "40%" : "50%",
                  }}
                />
              </CardLandingPageType2>
            </div>
          </div>

          <div
            className="w-100 d-flex flex-column flex-lg-row align-items-lg-end"
            style={{
              gap: isMobile ? 50 : 240,
              padding: isMobile ? "0 16px" : "0 0 30px 0",
            }}
          >
            {/* <div
              className="d-flex flex-column justify-content-between align-items-start gap-3"
              style={{
                minWidth: 192,
                minHeight: 162,
                background: COLORS.gray100,
                borderRadius: 8,
                padding: 16,
                maxWidth: isMobile && 380,
              }}
            >
              <span
                style={{
                  fontSize: 16,
                  color: COLORS.gray700,
                  maxWidth: !isMobile && 142,
                }}
              >
                {t("sectionOne.card")}
              </span>
              <button>
                {t("sectionOne.view")} <IconArrowRight size={16} />
              </button>
            </div> */}
            <div style={{ maxWidth: 730 }}>
              <h1 className="m-0 title-medium">
                {t("sectionOne.descriptionOne")}
              </h1>
              <br />
              <TitleColorsLanding
                title={t("sectionOne.descriptionTwo")}
                numLastWords={5}
                className={"title-medium"}
              />
            </div>
          </div>

          <div
            className="vw-100 d-flex justify-content-center flex-column align-items-center"
            style={{
              background: `linear-gradient(0deg, #FFFFFF ${
                isMobile ? "-35%" : "0%"
              }, #F2F4F7 100%)`,
              gap: isMobile ? 50 : 86,
            }}
          >
            <div
              className="d-flex flex-column justify-content-center align-items-center w-100"
              style={{
                padding: isMobile ? "60px 16px 0" : "150px 80px 0 80px",
                gap: 110,
                maxWidth: 1440,
              }}
            >
              <div style={{ maxWidth: 800 }}>
                <TitleLandingPage
                  title={t("sectionTwo.title")}
                  mediumFont
                  buttonText={"Get $AIZ"}
                  darkMode
                  headerText={t("sectionTwo.header")}
                  headerWidth={185}
                  background={COLORS.white}
                  borderRadius={100}
                  border={`1px solid ${COLORS.gray200}`}
                  textAlign={"center"}
                  justifyContent={"start"}
                  alignItems={"center"}
                  gap={isMobile ? 0 : 16}
                  styleMain={{ width: "100%" }}
                  styleDescription={{ color: COLORS.gray700 }}
                />
              </div>
            </div>
            <div
              className="w-100 d-flex flex-column"
              style={{ padding: isMobile ? "0 16px" : "0 80px 120px 80px" }}
            >
              <div className="d-flex flex-lg-row flex-column justify-content-center w-100 ">
                <div className="w-100 d-flex flex-lg-row flex-column justify-content-center gap-4">
                  <CardLandingPageType2
                    background={COLORS.white}
                    maxWidth={isMobile ? "100%" : 628}
                    minHeight={isMobile ? 300 : 323}
                    style={{ padding: isMobile ? "35px 16px" : "65px 50px" }}
                    className={
                      "d-flex justify-content-center justify-content-lg-start align-items-end"
                    }
                  >
                    <TokenSupplyComponent />
                  </CardLandingPageType2>
                  <CardLandingPageType2
                    background={COLORS.white}
                    maxWidth={isMobile ? "100%" : 628}
                    minHeight={isMobile ? 300 : 323}
                    style={{ padding: isMobile ? "46px 16px" : "46px 30px" }}
                    className={"d-flex justify-content-between"}
                  >
                    <TokenDistributionSplit />
                  </CardLandingPageType2>
                </div>
              </div>
              <div className="d-flex flex-lg-row flex-column justify-content-center w-100 gap-4 mt-4">
                <div
                  style={{
                    maxWidth: isMobile ? "100%" : 628,
                    minHeight: isMobile ? 260 : 280,
                    display: "grid",
                    gridTemplateColumns: isMobileMd
                      ? "repeat(1, 1fr)"
                      : "repeat(2, 1fr)",
                  }}
                  className={"w-100 gap-4"}
                >
                  <CardLandingPageType2
                    background={COLORS.white}
                    className={"position-relative"}
                  >
                    <p style={{ fontSize: 14, maxWidth: 150 }}>
                      {t("sectionTwo.cardDex.description")}
                    </p>
                    <img
                      src={jupter}
                      alt=""
                      style={{ position: "absolute", top: 111, left: 68 }}
                    />
                    <img
                      src={dex}
                      alt=""
                      style={{ position: "absolute", top: 171, left: 163 }}
                    />
                    <img
                      src={dextools}
                      alt=""
                      style={{ position: "absolute", top: 74, left: 179 }}
                    />
                  </CardLandingPageType2>
                  {!isMobileMd && (
                    <CardLandingPageType2
                      className={"p-0"}
                      background={COLORS.white}
                    >
                      <img
                        src={elipses}
                        alt=""
                        className="w-100"
                        style={{ objectFit: "cover" }}
                      />
                    </CardLandingPageType2>
                  )}
                </div>
                <CardLandingPageType2
                  background={COLORS.white}
                  maxWidth={isMobile ? "100%" : 628}
                  minHeight={isMobile ? 260 : 280}
                  className={
                    "d-flex justify-content-center justify-content-lg-start align-items-end position-relative"
                  }
                >
                  <SolanaNetwork />
                </CardLandingPageType2>
              </div>
            </div>
          </div>

          {/* <div
            className="w-100 d-flex justify-content-start"
            style={{ maxWidth: 1280, padding: isMobile && "0 16px" }}
          >
            <CardLandingPageType2
              style={{ padding: isMobile ? "40px 16px" : 70 }}
              background={COLORS.gray100}
              className={
                "d-flex justify-content-between flex-column flex-lg-row align-items-start"
              }
            >
              <TitleLandingPage
                title={t("building.title")}
                description={t("building.description")}
                darkMode
                mediumFont
                textAlign={"start"}
                justifyContent={"start"}
                widthTitle={420}
                alignItems={"start"}
                gap={isMobile ? 0 : 16}
                styleMain={{ width: "100%", color: COLORS.gray900 }}
                styleDescription={{
                  color: COLORS.gray700,
                  maxWidth: "100%",
                }}
              />
              <div className="d-flex flex-column gap-3 w-100">
                <div className="d-flex gap-2">
                  {buttons.map((item, index) => (
                    <ButtonType1
                      key={index}
                      variation="secondary"
                      text={item.text}
                      props={{
                        onClick: () => setActiveTab(item.text),
                        style: { padding: "6px 12px", borderRadius: "100px" },
                      }}
                    />
                  ))}
                </div>
                <CardLandingPageType2
                  background={COLORS.white}
                  maxWidth={isMobile ? "100%" : 508}
                  minHeight={isMobile ? 300 : 400}
                  className="container-iframe"
                >
                   <div className="iframe-wrapper">
                   {getIframeContent()}
                  </div> 
                </CardLandingPageType2>
              </div>
            </CardLandingPageType2>
          </div> */}

          {/* section contact */}
          <div
            className="vw-100 d-flex justify-content-center flex-column align-items-center"
            style={{
              background: "linear-gradient(0deg, #FFFFFF 0%, #F2F4F7 100%)",
              gap: 86,
            }}
          >
            <div
              className="w-100 d-flex flex-column align-items-center"
              style={{
                padding: isMobile ? "60px 16px" : "120px 80px",
              }}
            >
              <div
                className="d-flex flex-column w-100"
                style={{ gap: 120, maxWidth: 1280 }}
              >
                <div className="d-flex flex-column gap-5">
                  <TitleLandingPage
                    title={
                      <h1 className="m-0 w-100">{t("sectionThree.title")}</h1>
                    }
                    description={t("sectionThree.description")}
                    alignItems={"start"}
                    styleDescription={{
                      maxWidth: "100%",
                      margin: "16px 0 0 0",
                    }}
                  />
                  <div className="d-flex gap-4 flex-wrap">
                    <CardGetInTouch
                      text={t("sectionThree.links.github")}
                      link={"https://github.com/aizzy-ai"}
                    />
                    <CardGetInTouch
                      text={t("sectionThree.links.telegram")}
                      link={"https://t.me/aizzyai"}
                    />
                    <CardGetInTouch
                      text={t("sectionThree.links.discord")}
                      link={"https://discord.gg/aizzy"}
                    />
                    <CardGetInTouch
                      text={t("sectionThree.links.tiktok")}
                      link={"https://www.tiktok.com/@aizzy.ai"}
                    />
                    <CardGetInTouch
                      text={t("sectionThree.links.twitter")}
                      link={"https://x.com/aizzyai/"}
                    />
                  </div>
                </div>
                <CardLandingPageSectionFive />
              </div>
            </div>
          </div>
        </div>
        <FooterLandingPage />
      </div>
    </>
  );
};

export default Token;
