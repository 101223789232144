import { useSelector } from "react-redux";
import NavLandingPage from "../LandingPage/NavLandingPage";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import FooterLandingPage from "../LandingPage/FooterLandingPage";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import COLORS from "../../../utils/constants/colors";
import CardRoadmap from "../../Cards/CardRoadmap";
import CardRoadmapType1 from "../../Cards/CardRoadmapType1";
import CardLandingPageSectionFive from "../../Cards/CardLandingPageSectionFive";
import CardsSwiperRoadmap from "../../Cards/CardsSwiperRoadmap";
import CardStatusRoadmap from "../../Cards/CardStatusRoadmap";
import iconAizzyRoadmap from "../../../assets/images/icons-new-layout/aizzy-icon-roadmap.svg";
import iconRobotRoadmap from "../../../assets/images/icons-new-layout/robot-roadmap.svg";
import iconAizzyMobileRoadmap from "../../../assets/images/icons-new-layout/mobile-roadmap.svg";
import UseTranslation from "../../../hooks/useTranslation";
import { RoadmapTranslationsI18n } from "./RoadmapTranslations.i18n";
import TitleColorsLanding from "../../Add-ons/TitleColorsLanding";
import Timeline from "./TimeLine";

const Roadmap = () => {
  const state = useSelector(state => state);
  const { t } = UseTranslation(RoadmapTranslationsI18n);

  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG;
  return (
    <>
      <NavLandingPage />
      <div
        className="d-flex flex-column justify-content-between align-items-center w-100 gap-5 overflow-auto scroll-bar-none"
        style={{ paddingTop: isMobile ? "180px" : "140px", height: "100vh" }}
      >
        <div
          className="d-flex flex-column justify-content-center align-items-center w-100"
          style={{
            padding: !isMobile && "80px 80px 0 80px",
            gap: isMobile ? 40 : 80,
          }}
        >
          <TitleLandingPage
            title={<TitleColorsLanding title={t("journeyTitle")} numLastWords={1} className={"title-large"} />}
            description={t("journeyDescription")}
            darkMode
            headerText={t("journeyHeader")}
            headerWidth={86}
            borderRadius={100}
            border={`1px solid ${COLORS.gray200}`}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={16}
            styleMain={{ width: isMobile ? 350 : 550 }}
          />
          <Timeline />
        </div>
        <div
          className="d-flex flex-column justify-content-center align-items-center w-100"
          style={{
            padding: !isMobile && "80px 80px 0 80px",
            gap: isMobile ? 40 : 80,
          }}
        >
          <TitleLandingPage
            title={<TitleColorsLanding title={t("title")} numLastWords={3} className={"title-large"} />}
            darkMode
            headerText={t("header")}
            headerWidth={86}
            borderRadius={100}
            border={`1px solid ${COLORS.gray200}`}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={isMobile ? 0 : 3}
            styleMain={{ width: isMobile ? 350 : 550 }}
          />
          {isMobile ? (
            <CardsSwiperRoadmap />
          ) : (
            <div
              className="d-flex gap-4 cards-roadmap justify-content-center"
              style={{ maxWidth: 1440, overflowX: "hidden" }}
            >
              <CardRoadmap imagesCount={1} semesters={[1]} />
              <CardRoadmap
                cardCount={1}
                semesters={[3, 4]}
                year={2024}
                card={
                  <CardRoadmapType1
                    maxWidth={380}
                    text={
                      <>
                        <div className="d-flex justify-content-between align-items-center">
                          <h5> {t("cardOne.title")}</h5>
                          <CardStatusRoadmap
                            background={COLORS.green50}
                            status={t("cardOne.status")}
                            color={COLORS.green800}
                          />
                        </div>
                        <p className="m-0 font-size-12">
                          {t("cardOne.description")}
                        </p>
                      </>
                    }
                    img={iconAizzyRoadmap}
                    top={100}
                    left={15}
                  />
                }
                imagesCount={2}
              />
              <CardRoadmap
                cardCount={2}
                semesters={[1, 2, 3, 4]}
                year={2025}
                card={
                  <>
                    <CardRoadmapType1
                      maxWidth={600}
                      text={
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>{t("cardTwo.title")}</h5>
                            <CardStatusRoadmap
                              background={COLORS.gray100}
                              status={t("cardTwo.status")}
                              color={COLORS.gray500}
                            />
                          </div>
                          <p className="m-0 font-size-12">
                            {t("cardTwo.description")}
                          </p>
                        </>
                      }
                      img={iconRobotRoadmap}
                      top={200}
                      left={10}
                    />
                    <CardRoadmapType1
                      maxWidth={380}
                      text={
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <h5>{t("cardThree.title")}</h5>
                            <CardStatusRoadmap
                              background={COLORS.gray100}
                              status={t("cardThree.status")}
                              color={COLORS.gray500}
                            />
                          </div>
                          <p className="m-0 font-size-12">
                            {t("cardThree.description")}
                          </p>
                        </>
                      }
                      img={iconAizzyMobileRoadmap}
                      top={320}
                      left={230}
                    />
                  </>
                }
                imagesCount={4}
              />
            </div>
          )}
          <div
            className="w-100"
            style={{ maxWidth: 1280, padding: isMobile && "0 16px" }}
          >
            <CardLandingPageSectionFive />
          </div>
        </div>
        <FooterLandingPage />
      </div>
    </>
  );
};

export default Roadmap;
