import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { Select } from "antd";
import { toast } from "sonner";
import i18next from "i18next";
import detectBrowserLanguage from "detect-browser-language";
import { IconMenu2 } from "@tabler/icons-react";

import { AizzyLogo } from "../../AizzyLogo";
import ButtonType1 from "../../Buttons/ButtonType1";
import COLORS from "../../../utils/constants/colors";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import UseTranslation from "../../../hooks/useTranslation";
import { showErrorNotification } from "../../../services/notifications";
import FlagBrSvg from "../../../assets/images/icons/survey/flag-br.svg";
import FlagEsSvg from "../../../assets/images/icons/survey/flag-es.svg";
import FlagUsSvg from "../../../assets/images/icons/survey/flag-eus.svg";
import { LandingPageI18n } from "./LandingPageTranslactions";
import MenuMobile from "./MenuMobile";

const NavLandingPage = () => {
  const state = useSelector(state => state);
  const supabaseClient = useSupabaseClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = UseTranslation(LandingPageI18n);

  const [language, setLanguage] = useState();
  const [openMenuMobile, setOpenMenuMobile] = useState(false);

  const languageOptions = [
    { code: "en-US", name: "US", flag: FlagUsSvg },
    { code: "pt-BR", name: "PT", flag: FlagBrSvg },
    { code: "es-ES", name: "ES", flag: FlagEsSvg },
  ];

  useEffect(() => {
    const savedConfig = JSON.parse(localStorage.getItem("configsGeneralReducer"));
    const defaultLanguage = savedConfig?.language || detectBrowserLanguage() || "en-US";
  
    i18next.changeLanguage(defaultLanguage);
    setLanguage(
      languageOptions.find(lang => lang.code === defaultLanguage)?.name || "US"
    );
  }, []);
  

  const handleLanguageChange = async selectedLanguage => {
    const selectedOption = languageOptions.find(lang => lang.name === selectedLanguage);
    setLanguage(selectedLanguage);
  
    try {
      localStorage.setItem(
        "configsGeneralReducer",
        JSON.stringify({ language: selectedOption.code })
      );
  
      i18next.changeLanguage(selectedOption.code);
  
      toast.loading("Updating language...");
      await supabaseClient.auth.updateUser({
        data: { language: selectedOption.code },
      });
      await supabaseClient.auth.refreshSession();
  
      window.location.reload();
    } catch (error) {
      console.error(error);
      showErrorNotification("Error updating language");
    }
  };
  

  const handleNavigationClick = sectionId => {
    if (location.pathname === "/") {
      scrollToSection(sectionId);
    } else {
      navigate("/");
      setTimeout(() => scrollToSection(sectionId), 500);
    }
  };

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const navigationLinks = [
    {
      title: "AIZ Token",
      link: "/token",
      disabled: location.pathname === "/maintenance",
    },
    {
      title: t("nav.about"),
      onClick:
        location.pathname !== "/maintenance" &&
        (() => handleNavigationClick("about")),
      disabled: location.pathname === "/maintenance",
    },
    {
      title: t("nav.capabilities"),
      onClick:
        location.pathname !== "/maintenance" &&
        (() => handleNavigationClick("capabilities")),
      disabled: location.pathname === "/maintenance",
    },
    {
      title: "FAQ",
      onClick:
        location.pathname !== "/maintenance" &&
        (() => handleNavigationClick("faqs")),
      disabled: location.pathname === "/maintenance",
    },
    {
      title: "Roadmap",
      link: "/roadmap",
      disabled: location.pathname === "/maintenance",
    },
  ];

  const isMobileView =
    state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  useEffect(() => {
    if (!isMobileView) setOpenMenuMobile(false);
  }, [isMobileView]);

  const handleRedirect = () => {
    if (location.pathname !== "/maintenance") {
      const redirectUrl = process.env.REACT_APP_PUBLIC_SITE_URL;
  
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        console.error("Erro ao redirecionar.");
      }
    }
  };
  

  return (
    <>
      <section
        className="d-flex justify-content-center align-items-center"
        style={{
          position: "fixed",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          height: isMobileView ? "100px" : "130px",
          padding: !isMobileView && "0 80px",
          zIndex: 100,
        }}
      >
        <nav
          className="w-100 d-flex justify-content-between align-items-center"
          style={{
            border: `1px solid ${COLORS.gray100}`,
            background: COLORS.white,
            borderRadius: isMobileView ? 16 : 24,
            padding: isMobileView ? "15px" : "24px",
            maxWidth: isMobileView ? "calc(100% - 32px)" : 1280,
          }}
        >
          <div
            className="cursor-pointer"
            onClick={handleRedirect}
          >
            <AizzyLogo
              width={isMobileView ? 83 : 111}
              height={isMobileView ? 24 : 32}
            />
          </div>
          <div className="d-flex align-items-center gap-4 nav-landing-page">
            {!isMobileView && (
              <ul className="d-flex gap-3 nav-links">
                {navigationLinks.map(({ title, link, onClick, disabled }) => (
                  <li
                    key={title}
                    className={`font-size-14 nav-item`}
                    style={{
                      pointerEvents: disabled ? "none" : "auto",
                      color: disabled ? COLORS.gray300 : COLORS.gray500,
                      cursor: disabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {onClick ? (
                      <button
                        className="nav-link"
                        onClick={onClick}
                        style={{
                          background: "none",
                          border: "none",
                          color: disabled ? COLORS.gray300 : COLORS.gray500,
                          cursor: disabled ? "not-allowed" : "pointer",
                        }}
                      >
                        {title}
                      </button>
                    ) : (
                      <Link
                        to={link}
                        className="nav-link"
                        style={{
                          color: disabled ? COLORS.gray300 : COLORS.gray500,
                          pointerEvents: disabled ? "none" : "auto",
                        }}
                        target={title === t("nav.about") ? "_blank" : undefined}
                      >
                        {title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            )}

            <div className="d-flex gap-2 align-items-center">
              {!isMobileView && (
                <Select
                  className="select-language-antd"
                  style={{ height: 33, minWidth: 85, fontSize: 14 }}
                  value={language}
                  onChange={handleLanguageChange}
                >
                  {languageOptions.map(({ code, name, flag }) => (
                    <Select.Option key={code} value={name}>
                      <div className="d-flex align-items-center">
                        <img
                          src={flag}
                          alt={name}
                          style={{ width: 18, height: 14, marginRight: 8 }}
                        />
                        <span className="font-size-14">{name}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              )}

              <ButtonType1
                text={t("button")}
                props={{
                  onClick: () =>
                    (window.location.href =
                      process.env.REACT_APP_PUBLIC_SITE_URL),
                  disabled: location.pathname === "/maintenance",
                  style: {
                    borderRadius: "8px",
                    padding: "6px 12px",
                  },
                }}
              />

              {isMobileView && (
                <ButtonType1
                  props={{
                    onClick: () => setOpenMenuMobile(!openMenuMobile),
                    style: {
                      borderRadius: "10px",
                      background: COLORS.gray100,
                      width: 32,
                      height: 32,
                    },
                  }}
                  variation="secondary"
                  text={<IconMenu2 size={16} stroke={1.5} />}
                />
              )}
            </div>
          </div>
        </nav>
      </section>

      {openMenuMobile && (
        <MenuMobile
          openMenuMobile={() => setOpenMenuMobile(!openMenuMobile)}
          breakpointLg={isMobileView}
          state={state}
          language={language}
          languageOptions={languageOptions}
          handleLanguageChange={handleLanguageChange}
        />
      )}
    </>
  );
};

export default NavLandingPage;
