import { useSelector } from "react-redux";
import circle from "../../../assets/images/icons-new-layout/circle-token-split.png";
import UseTranslation from "../../../hooks/useTranslation";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import PageTokenTranslations from "./PageTokenTranslationsi18n";

const TokenDistributionSplit = () => {
  const state = useSelector((state) => state);
  const { t } = UseTranslation(PageTokenTranslations);
  const isMobile = state.configsGeneralReducer.windowWidth < BREAKPOINTS.LG; 
  
  return (
    <div className="token-distribution-container" style={{ gap: isMobile ? "16px" : "50px" }}>
      <div className="d-flex flex-column content-title-and-dot">
        <h2 className="title m-0">{t("sectionTwo.cardDistribution.split")}</h2>
        <div className="circle">
          <img src={circle} alt="" width={"100%"}/>
          <div className="dot-page-token"></div>
        </div>
      </div>
      <div className="content">
        {/* Gráfico circular */}

        {/* Informações de distribuição */}
        <div className="distribution-info">
          <ul className="locked-info">
            <li style={{ fontWeight: 600 }}>
              <span className="locked-dot"></span>
              {t("sectionTwo.cardDistribution.locked")} (TBD)
            </li>
            <li>
              {t("sectionTwo.cardDistribution.phase")} 1 (TBD) — Phase 2 (TBD)
            </li>
            <li>
              {t("sectionTwo.cardDistribution.phase")} 3 (TBD) — Phase 4 (TBD)
            </li>
          </ul>
          <hr />
          <ul className="unlocked-info">
            <li style={{ fontWeight: 600 }}>
              <span className="unlocked-dot"></span>
              {t("sectionTwo.cardDistribution.unlocked")} (TBD)
            </li>
            <li>{t("sectionTwo.cardDistribution.about")}</li>
            <li>{t("sectionTwo.cardDistribution.company")}</li>
            <li>{t("sectionTwo.cardDistribution.founder")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TokenDistributionSplit;
