import { IconArrowRight } from "@tabler/icons-react";
import PageTokenTranslations from "./PageTokenTranslationsi18n";
import UseTranslation from "../../../hooks/useTranslation";

const SolanaNetwork = () => {
  const { t } = UseTranslation(PageTokenTranslations);
  return (
    <div className="solana-network">
      <div>
        <div className="header">
          <p>{t("sectionTwo.cardSolanaNetwork.building")}</p>
          <h1>{t("sectionTwo.cardSolanaNetwork.solana")}</h1>
        </div>
        <div className="content">
          <ul>
            <li>
              <IconArrowRight size={16} stroke={1.2} />
              {t("sectionTwo.cardSolanaNetwork.lower")}
            </li>
            <li>
              <IconArrowRight size={16} stroke={1.2} />
              {t("sectionTwo.cardSolanaNetwork.faster")}
            </li>
            <li>
              <IconArrowRight size={16} stroke={1.2} />
              {t("sectionTwo.cardSolanaNetwork.enhanced")}
            </li>
          </ul>
        </div>
      </div>
      <div className="graphics">
        <div className="graphic green"></div>
        <div className="graphic blue"></div>
        <div className="graphic purple"></div>
      </div>
    </div>
  );
};

export default SolanaNetwork;
