/* eslint-disable no-unused-expressions */
import COLORS from "../../../utils/constants/colors";
import imgMenuWallet from "../../../assets/images/icons-new-layout/menu-web3.png";
import imgMenuSelectAssistant from "../../../assets/images/icons-new-layout/menu-selelct-assistant.webp";
import previewSite from "../../../assets/images/icons-new-layout/container.png";
import TitleLandingPage from "../LandingPage/TitleSectionLandingPage";
import { useSelector } from "react-redux";
import BREAKPOINTS from "../../../utils/constants/breakpoints";
import groupDots from "../../../assets/images/icons-new-layout/groupDotsLigth.svg";
import UseTranslation from "../../../hooks/useTranslation";
import { LandingPageI18n } from "./LandingPageTranslactions";
import { useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const SectionOneLandingPage = () => {
  const state = useSelector(state => state);

  const { t } = UseTranslation(LandingPageI18n);

  const lg = state?.configsGeneralReducer?.windowWidth < BREAKPOINTS.LG;

  useLayoutEffect(() => {
    const scroller = document.querySelector(".main-landing-page") || window;

    gsap.registerPlugin(ScrollTrigger);

    requestAnimationFrame(() => {
      ScrollTrigger.defaults({
        scroller,
      });

      gsap.from(".preview-site", {
        opacity: 1,
        scale: 0.2,
        duration: 1,
        ease: "power4.out",
      });

      gsap.from(".menu-wallet", {
        opacity: 1,
        duration: 1,
        delay: 0.3,
        ease: "power4.out",
      });

      gsap.from(".menu-assistant", {
        opacity: 1,
        duration: 1,
        delay: 0.3,
        ease: "power4.out",
      });

      gsap.to(".preview-site", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".section-one",
          start: "top top",
          end: "bottom top",
          scrub: true,
          scroller,
        },
      });

      gsap.to([".menu-wallet", ".group-dots1"], {
        x: "-150%",
        opacity: 0,
        scrollTrigger: {
          trigger: ".section-one",
          start: "top top",
          end: "bottom top",
          scrub: true,
          scroller,
        },
      });

      gsap.to([".menu-assistant", ".group-dots2"], {
        x: "150%",
        opacity: 0,
        scrollTrigger: {
          trigger: ".section-one",
          start: "top top",
          end: "bottom top",
          scrub: true,
          scroller,
        },
      });
    });

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);

    return () => ScrollTrigger.getAll().forEach(trigger => trigger.kill());
  }, []);

  return (
    <section
      className="d-flex justify-content-center align-items-center section-one overflow-hidden"
      style={{
        width: "100vw",
        background: "linear-gradient(180deg, #FFFFFF 0%, #F2F4F7 100%)",
        padding: lg ? "0 16px 80px 16px" : "0 120px 120px 120px",
      }}
    >
      <div
        className="section-one w-100 position-relative"
        style={{
          position: "relative",
          maxWidth: 1440,
        }}
      >
        {!lg && (
          <>
            <img
              src={groupDots}
              alt=""
              className="group-dots1"
              style={{
                position: "absolute",
                bottom: 190,
                left: 60,
                zIndex: 0,
              }}
            />
            <img
              src={groupDots}
              alt=""
              className="group-dots2"
              style={{
                position: "absolute",
                top: 474,
                right: 92,
                zIndex: 0,
              }}
            />
          </>
        )}
        <div
          className="w-100 d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: lg ? 40 : 80, gap: lg ? 40 : 56 }}
        >
          <TitleLandingPage
            className="title-landing-page"
            darkMode
            largeFont={true}
            gap={24}
            alignItems={"center"}
            textAlign={"center"}
            widthTitle={500}
            justifyContent={"center"}
            headerText={t("sectionOne.badge")}
            headerWidth={100}
            border={`1px solid ${COLORS.gray200}`}
            borderRadius={100}
            title={t("sectionOne.title")}
            description={t("sectionOne.description")}
          />
          <div
            className="position-relative w-100 d-flex justify-content-center"
            style={{ maxWidth: 1000 }}
          >
            <img
              src={previewSite}
              alt=""
              style={{ borderRadius: lg ? 6 : 16, width: "100%" }}
              className="preview-site"
            />
            {!lg && (
              <>
                <img
                  src={imgMenuWallet}
                  alt=""
                  className="menu-wallet position-absolute"
                  style={{ left: -50, top: 228 }}
                />
                <img
                  src={imgMenuSelectAssistant}
                  alt=""
                  className="menu-assistant position-absolute"
                  style={{ right: -120, bottom: 100, width: 205, height: 248 }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionOneLandingPage;
